$primary-color: #03306C;
$text-color: #323338;
$bg-color: #EBF6FF;
$white-color: #ffffff;
$border-color: #707070;

@import 'loaders.css/src/animations/ball-pulse.scss';
 
html, body{
  font-size: 14px;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color:$text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .MuiTooltip-tooltip	{
    background-color: #fff;
    font-size: 11px;
    color: rgba($color: #323338, $alpha: 0.8);
    border: 1px solid #A8BAD0;
    .MuiTooltip-arrow {
      &::before {
        background-color: #fff;
        border: 1px solid #A8BAD0;
      }
    }
  }
}

.loader {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1999;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: flex;
}

// Material UI Overwrite Css
.app-body {
  min-height: 100vh;
  background: url(./assets/bottom-bg.svg) repeat-x bottom center, url(./assets/left-bg.svg) no-repeat 10% 30%, url(./assets/right-bg.svg) no-repeat 90% 30%;
  background-color: $bg-color;
  &.inner-page {
    background-color: $white-color;
  }
  &.not-found{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &::before {
    width: 100%;
    height: 5px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color:$primary-color;
  }
  .MuiPaper-root.custom-paper {
    color: $text-color;
    border:1px solid #E3EFFF;
    border-radius: 3px;
    background-color: $white-color;
    box-shadow:5px 5px 20px $bg-color;
    &.pay-off-paper {
      background-color: #EBF6FF;
    }
    
  }
  .MuiTypography-body2 {
    a {
      color: #0076EB;
    }
  }
  .MuiTypography-h1 {
    font-size: 1.29rem;
    font-weight: 400;
    //margin-bottom:2rem;
  }
  .MuiButton-contained, .MuiButton-text {
    font-size: 1rem;
    border-radius: 30px;
    font-weight: normal;
    &.MuiButton-containedPrimary {
      background-color: $primary-color;
      color: $white-color;
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
  .MuiFormLabel-root {
    &.Mui-error {
      color: inherit;
    }
  }
}

// Header Css
.header {
  &.MuiAppBar-colorPrimary {
    background-color: #ffffff;
    color: $primary-color;
    border-top:5px solid $primary-color;
    box-shadow:5px 5px 20px $bg-color;
  }
  .MuiToolbar-regular {
    min-height: 55px;
  }
  .tool-bar {
    justify-content: space-around;
    .logo-img {
      height: 40px;
      cursor: pointer;
    }
    .MuiAvatar-circle {
      border: 1px solid rgba($color: $border-color, $alpha: 0.25);
      background-color: $bg-color;
      color: $primary-color;
    }
    
  }
}

// Global Search Css
.main-container {
  min-height:100vh;
  @media only screen and (max-width: 991px) {
    padding-top: 25px;
    &.MuiGrid-align-items-xs-center {
      align-items:flex-start;
    }
  }
  .search-container {
    width: 90%;
    max-width: 480px;
    .search-paper {
      border:1px solid $bg-color;
      box-shadow:0 0 25px #A7BBD2;
      border-radius: 10px;
      padding: 45px;
      @media only screen and (max-width: 1440px) {
        padding:30px 40px;
      }
      @media only screen and (max-width: 600px) {
        padding:30px 18px;
      }
      .logo-box {
        text-align: center;
        margin-bottom:60px;
        @media only screen and (max-width: 1440px) {
          margin-bottom:50px;
        }
        @media only screen and (max-width: 600px) {
          margin-bottom:25px;
        }
        img {
          height: 50px;
        }
      }
      .grid-row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;
        .captcha-box {
          flex: 1;
          img {
            border: 1px solid #A8BAD0;
            border-radius: 2px;
            max-width: 100%;
            height: 40px;
          }
        }
        .icon-box {
          width: 16px;
          text-align: right;
        }
      }
      .MuiFormControl-root {
        flex: 1;
        margin-right: 25px;
        .MuiInputLabel-formControl {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
          &.Mui-error {
            color: inherit;
          }
        }
      }
      .MuiFormHelperText-root.Mui-error {
        position: absolute;
        top: 100%;
        white-space: nowrap;
      }
    }
  }
}
.help-text {
  padding-top: 13px;
  text-align: center;
  &.inner-page{
    max-width: 80%;
    margin: 0 auto;
  }
}
// Sub Header
.sub-header {
  background-color: $primary-color;
  z-index: 1101;
  position: relative;
  color: $white-color;
  display: flex;
  min-height: 66px;
  .back-button {
    width: 60px;
    color: $white-color;
  }
  .sub-header-list {
    display: flex;
    // flex: 1;
    overflow-y: auto;
    .sub-header-list-item {
      width: auto;
      white-space: nowrap;
      padding: 0 16px;
      @media only screen and (max-width: 1280px) {
        padding: 0 10px;
      }
      .MuiTypography-body1 {
        font-size: 0.85rem;
        font-weight: bold;
        color: inherit;
      }
      .MuiTypography-body2 {
        color: inherit;
        font-size: 1rem;
        display: flex;
        > span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          @media only screen and (max-width: 1366px) {
            max-width: 120px;
          }
        }
      }
      @media only screen and (max-width: 1280px) {
        &.hide-on-small {
          display: none;
        }
      }
    }
  }
  .show-on-small {
    display: none;
    padding: 0 10px;
    @media only screen and (max-width: 1280px) {
      display: flex;
      align-items: center;
    }
  }
}

.asset-detail-container {
  display: flex;
  height:calc(100vh - 133px);
  .side-nav {
    width: 60px;
    text-align: center;
    box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.08);
    background-color: $white-color;
    overflow-x: hidden;
    overflow-y: auto;
    .side-nav-list {
      width: 35px;
      padding: 0;
      margin: 0 auto;
      li {
        width: 35px;
        margin: 0;
        padding: 10px 0;
        border-bottom:1px solid rgba($color: $border-color, $alpha: 0.45);
        .MuiListItemAvatar-root {
          min-width: auto;
        }
        .MuiAvatar-root {
          width: 35px;
          height: 35px;
          background-color: transparent;
        }
        span.icon {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
          background-color: transparent;
          background-image: url(./assets/icons/nav-icons.svg);
          background-repeat: no-repeat;
          &.certificate-icon {
            background-position: 0 0;
          }
          &.transaction-icon {
            background-position: 0 -35px;
          }
          &.history-icon {
            background-position: 0 -70px;
          }
          &.notes-icon {
            background-position: 0 -105px;
          }
          &.link-icon {
            background-position: 0 -140px;
          }
          &.payoff-icon {
            background-position: 0 -175px;
          }
          &.documents-icon {
            background-position: 0 -210px;
          }
          &.contact-icon {
            background-position: 0 -245px;
          }
          &.fees-icon {
            background-position: 0 -280px;
          }

          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.04);
          }

        }

        .selected {
          span.icon {
            background-color: rgba($color: $primary-color, $alpha: 1);
            background-position-x: 100%;
          }
        }
      }
    }
  }
  .detail-section {
    flex: 1;
    padding:20px;
    overflow-x: hidden;
    overflow-y: auto;
    .detail-heading {
      padding-bottom: 25px;
    }
    .detail-list {
      width: 100%;
      max-width: 1024px;
      display: flex;
      flex-wrap: wrap;
      .detail-list-item {
        width: 33%;
        @media only screen and (max-width: 991px) {
          width: 50%;
        }
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
        .MuiFormControl-root {
          width: 100%;
        }
        .MuiTypography-body1 {
          color: $primary-color;
        }
        .MuiTypography-body2 {
          font-size: 1.14rem;
          color: #484848;
          .status {
            font-size: 0.85rem;
            padding: 2px 15px 1px;
            text-transform: uppercase;
            border-radius: 3px;
            display: inline-block;
            background-color: #D0F2CA;
            &.active {
              background-color: #D0F2CA;
              color: #349A7B;
            }
            &.inactive {
              background-color: #eaeaea;
              color: #484848;
            }
          }
        }
      }
      &.contact-list {
        .detail-list-item {
          width: 50%;
          padding-bottom: 20px;
          @media only screen and (max-width: 991px) {
            width: 100%;
          }          
        }
      }
    }
  }
}

.asset-list-section {
  padding: 25px;
  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
}
.heading-section {
  min-height: 42px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .MuiIconButton-root {
    padding: 6px;
  }
  .heading-title {
    flex:1;
  }
  .heading-buttons {
    button {
      margin-left: 10px;
    }
  }
}

.common-table {
  border: 1px solid #EFEFEF;
  border-radius: 3px;
  background-color: $white-color;
  .MuiTableCell-root {
    padding: 14px 25px;
    color: #484848;
    white-space: nowrap;
    @media only screen and (max-width: 600px) {
      padding: 14px 15px;
    }
  }
  .MuiTableCell-head {
    background-color: $bg-color;
    font-size: 0.786rem;
    font-weight: 500;
    padding: 9px 25px;
    color: #323338;
    @media only screen and (max-width: 600px) {
      padding: 9px 15px;
    }
  }
}
.link {
  color: #4583FE;
  cursor: pointer;
  font-weight: 500;
}

.pagination {
  .MuiTablePagination-toolbar{
    min-height:42px;
  }
  .MuiTablePagination-caption {
    font-size: 1rem;
    font-weight: bold;
    color: $border-color;
  }
  .MuiTablePagination-actions {
    margin-left: 8px;
  }
  .MuiIconButton-root {
    border: 1px solid rgba($color: #484848, $alpha: 0.3);
    padding: 6px;
    margin-left: 2px;
  }
}
.MuiIconButton-root {
  &.popover-icon {
    color: inherit;
    padding: 0 0 0 3px;
    font-size: inherit;
    font-weight: 500;
  }
  &.square-icon-button {
    border-radius: 3px;
    border: 1px solid rgba($color: #707070, $alpha: 0.25);
    background-color: #EBF6FF;
  }
  &.popover-button {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid rgba($color: #DBCFB8, $alpha: 1);
    background-color: #FFFFFF;
    box-shadow: 2px 0 3px #354517;
    &:hover {
      background-color: #FFFFFF;
    }
  }
}
.popover-opener {
  cursor: pointer;
  font-weight: 500;
}
.simple-popover-dropdown-wrapper {
  &.hover {    
      pointer-events: none;
      .popover-list {
        max-height: unset;
      }
  }
  .popover-list {
    max-width: 340px;
    min-width: 260px;
    padding: 8px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
    overflow: visible;
    max-height: 220px;
    overflow-y: auto;
    .popover-list-item {
      letter-spacing: 0px;
      padding: 6px 8px;
      > h4 {
        margin: 0;
        color: #805537;
        font-size: 12px;
        line-height: 22px;
        font-weight: bold;
      }
      > p {
        margin: 0px;
        font-size: 14px;
        line-height: 22px;
        color: #4D4F5C;
        display: flex;
      }
      &.owner-item {
        position: relative;
        padding-left: 24px;
        &::before {
          content: ""; 
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 13px;
          display: inline-block;
          position: absolute;
          left: 10px;
          top: 10px;
        }
      }
      .popover-list-item-inner {
        display: flex;
        span.inner-title {
          font-weight: 500;
          padding-right:5px;
          white-space: nowrap;
        }
      }
    }
  }
  .MuiPopover-paper {
    overflow: visible;
    margin-top: 8px;
    margin-bottom: 0px;
    &::before {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      left: 50%;
      top: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #ddd;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: translateY(-19px) translateX(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      width: 0px;
      height: 0px;
      left: 50%;
      top: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #fff;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: translateY(-19px) translateX(-50%);
    }
  }
}

.no-records {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1.MuiTypography-h1 {
    font-size: 1.72rem;
  }
  .MuiButton-root {
    margin-top: 60px;
  }
  > div {
    text-align: center;
  }
  &.table-no-record {
    height:auto;
    min-height: 30vh;
  }
}

.custom-tabs {
  background-color: #F4F4F4;
  .MuiTabs-indicator {
    background-color: $primary-color;
  }
  .MuiTab-root {
    font-size: 1rem;
  }
}

// Page Not Found
.page-not-found {
  text-align: center;
  .MuiTypography-h1 {
    letter-spacing: 0;
    color: #969797;
    font-size: 39px;
    line-height: 52px;
    font-weight: 500;
  }
  .MuiTypography-h4 {
    letter-spacing: 0;
    color: #969797;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}


.pay-off-paper {
  background-color: #EBF6FF;
  .custom-tabs {
    background-color: #EBF6FF;
  }
  .pay-off-table {
    background-color: rgba($color: #fff, $alpha: 0);
    .MuiTableCell-head { white-space: normal;}
    .MuiTableBody-root {
      background-color: rgba($color: #fff, $alpha: 0);
      .MuiTableRow-root {
        background-color: #fff;
        &:last-child {
          background-color: rgba($color: #fff, $alpha: 0);
          .MuiTableCell-root {
            font-weight: bold;
            background-color: rgba($color: #fff, $alpha: 0);
          }
          .MuiCheckbox-root,.no-content {display: none;}
        }
      }
    }
  }
}
.pay-off-dialog {
  .MuiDialog-paper {
    max-width: 500px;
    width: 80vw;
    text-align: center;
  }
  #form-dialog-title {
    padding: 0;
    background: rgb(0,88,175);
    background: linear-gradient(0deg, rgba(0,88,175,1) 0%, rgba(3,48,108,1) 100%);
  }
  .MuiDialogActions-root  {
    justify-content: center ;
    padding-bottom: 20px;
  }
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiDialogContent-root {
    padding: 20px;
  }
  .MuiDialogContentText-root {
    padding-top:20px;
  }

  .MuiButton-contained, .MuiButton-text {
    font-size: 1rem;
    border-radius: 30px;
    font-weight: normal;
    &.MuiButton-containedPrimary {
      background-color: $primary-color;
      color: $white-color;
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.new-payment-btn {
    display: block !important;
    position: absolute !important;
    right: 30px !important;
    bottom: 10px !important;
}

